<template>
    <v-dialog
        v-model="dialog"
        max-width="400"
        persistent
    >
        <v-card>
                <v-card-title
                    class="headline grey lighten-2"
                    primary-title
                >
                    {{nombre}} 
                </v-card-title>
                <v-card-text class="pt-2">
                    <!-- ---------------------------------------------------- -->
                    
                    <v-select
                        v-model="vals.ocular"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.ocular"
                        label="Respuesta OCULAR"
                    ></v-select>

                    <v-select
                        v-model="vals.verbal"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.verbal"
                        label="Respuesta VERBAL"
                    ></v-select>

                    <v-select
                        v-model="vals.motor"
                        rounded
                        filled
                        clearable
                        :items="itemsEscala.motora"
                        label="Respuesta MOTORA"
                    ></v-select>
                    
                    <v-text-field
                        v-model="observaciones"
                        
                        clearable
                        dense
                        label="Observaciones"
                    ></v-text-field>

                    <v-expand-transition>
                        <div v-if="sumaValoracion>0" class="text-center">
                            <div class="title">Puntaje: {{sumaValoracion}}</div>
                            <div>{{textoValoracion}}</div>
                        </div>
                    </v-expand-transition>

                    <!-- ---------------------------------------------------- -->
                </v-card-text>

                <v-card-actions>
                    
                    <v-btn
                        color="success"
                        :loading="loading"
                        :disabled="!sumaValoracion"
                        @click="agrega"
                    >
                        Agregar
                    </v-btn>
                    
                    <v-btn color="error" @click="cancela">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogDe:Boolean,
        turno:String,
        nombre:String,
        estaFecha: String,
        internamiento:Number,
    },

    data:()=>({
        loading:false,
        observaciones:'',
        vals:{
            ocular:0,
            verbal:0,
            motor:0,
        },
        itemsEscala:{
            ocular:[
                {value:4, text: 'Espontanea'},
                {value:3, text: 'A la orden/Al grito'},
                {value:2, text: 'Ante dolor'},
                {value:1, text: 'Ausencia'},
            ],
            verbal:[
                {value:5, text:'Coherente/Balbucea'},
                {value:4, text:'Confuso/Llanto consolable'},
                {value:3, text:'Inapropiado/Llanto persistente'},
                {value:2, text:'Incomprensible/Solo ruidos'},
                {value:1, text:'Sin respuesta'},
            ],
            motora:[
                {value:6, text:'Obedece órdenes/Espontanea'},
                {value:5, text:'Localiza dolor'},
                {value:4, text:'Defensa al dolor'},
                {value:3, text:'Flexión anormal'},
                {value:2, text:'Extensión anormal'},
                {value:1, text:'Ninguna'},
            ],
        },
    }),

    computed:{
        dialog:{
            get(){
                return this.dialogDe
            },
            set(val){
                this.$emit('update:dialogDe',val)
            },
        },
        sumaValoracion(){
            if (this.vals.ocular>0 && this.vals.verbal>0 &&  this.vals.motor>0){
                return (parseInt(this.vals.ocular) + parseInt(this.vals.verbal) + parseInt(this.vals.motor))
            } else {
                return null
            }
        },
        textoValoracion(){
            if (this.sumaValoracion){
                let text = ''
                if (this.sumaValoracion <= 8)
                    text = 'Trauma severo'
                if (this.sumaValoracion > 8 && this.sumaValoracion <= 12)
                    text = 'Trauma moderado'
                if (this.sumaValoracion > 12)
                    text = 'Trauma Leve'
                return text
            } else {
                return null
            }
        }
    },
    methods:{
        cancela(){
            this.vals.ocular = this.vals.verbal = this.vals.motor = 0
            this.dialog = false
            this.observaciones = ''
        },
        async agrega(){

            this.loading=true

            let datos = {
                fecha: this.estaFecha,
                turno: this.turno,
                escala: 'conciencia',
                valor: JSON.stringify(this.vals),
                internamiento:this.internamiento,
                observaciones: this.observaciones
            }
            try{
                let dt = await this.$http({
                    url:'/enfermeria/guardaEscalas',
                    method: 'POST',
                    data: datos
                })
                if (dt.data.estado == true){
                    this.$emit('escalaSuccess')
                    this.vals.ocular = this.vals.verbal = this.vals.motor = 0
                    this.observaciones = ''
                    this.loading = false
                    this.dialog = false

                } else {
                    console.log(dt.data)
                    this.loading = false
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
                
            } catch(err){
                console.log(err)
                this.$store.dispatch('security/revisaError',err)
                this.loading = false
            }




            //this.vals.ocular = this.vals.verbal = this.vals.motor = 0
            //console.log ('Actualizando CONCIENCIA en en turno '+this.turno+' con los valores '+{})
            //this.$emit('escalaSuccess')
            //this.dialog = false
            
        },
    },
    
}
</script>

<style>

</style>